class Token {
  #token = null;
  #role = null;

  constructor(token) {
    this.#token = token;
  }

  get token() {
    return this.#token;
  }

  get role() {
    return this.#role;
  }
}

export let instanceToken = "start";

export const initToken = (token, role) => {
  instanceToken = new Token(token, role);
};
// export const BACKEND_URL = "http://localhost:3001/";
export const IMAGE_PATH =
  process.env.REACT_APP_SWITCH === "development"
    ? process.env.REACT_APP_IMAGE_PATH_UAT
    : process.env.REACT_APP_IMAGE_PATH_PROD;
export const BACKEND_URL =
  process.env.REACT_APP_SWITCH === "development"
    ? process.env.REACT_APP_API_URL_UAT
    : process.env.REACT_APP_API_URL_PROD;
export const BACKEND_URL_CLAIM =
  process.env.REACT_APP_SWITCH === "development"
    ? process.env.REACT_APP_API_URL_CLAIM_UAT
    : process.env.REACT_APP_API_URL_CLAIM_PROD;
