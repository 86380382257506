import axios from "axios";
import { BACKEND_URL_CLAIM } from "../utils/constant";

const ClaimLogin = async (credentials) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const response = await axios.post(
    `${BACKEND_URL_CLAIM}auth/authenticateLvl3`,
    credentials,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ClaimLogin,
};
